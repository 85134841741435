.single-realisation-page {
  /* Slider styling */

  .slideshow-container {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1000px;
  }

  .slide {
    display: none;
  }

  .slide > img {
    max-width: 1000px;
    max-height: 800px;
    margin: 0 auto;
    object-fit: cover;
    border-radius: 20px;
  }

  .slide-prev,
  .slide-next {
    cursor: pointer;
    color: $white;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: auto;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 6px;
    user-select: none;
  }

  .slide-prev {
    left: 5%;
  }
  .slide-next {
    right: 5%;
  }

  .slide-prev:hover,
  .slide-next:hover {
    text-decoration: none;
    color: $black;
    background-color: $secondary;
  }

  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {
      opacity: 0.6;
    }
    to {
      opacity: 1;
    }
  }

  .displayblock {
    display: flex;
    justify-content: center;
  }

  @media all and (max-width: 1200px) {
    .slideshow-container {
      border-radius: 20px;
    }
  }

  @media all and (max-width: 800px) {
    .slideshow-container {
      max-height: 500px;
      .slide > img {
        max-height: 500px;
      }
    }
  }

  @media all and (max-width: 600px) {
    .slideshow-container {
      max-height: 300px;
      .slide > img {
        width: 100%;
        max-height: 300px;
      }
    }
  }
}
