.survey {
  h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
}

.survey-panel {
  margin: 0 auto;
  h2 {
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  fieldset {
    width: 90%;
    margin: 0 auto;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    label > input {
      visibility: hidden;
    }
    label {
      width: 100%;
      border-radius: 10px;
      background: #f5f8ff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 1rem;
      margin-left: 10px;
      margin-right: 10px;
    }

    label.active-choice > div.checkmark {
      position: absolute;
      padding: 0px;
      height: 16px;
      width: 16px;
      right: 10px;
      background: transparent;
      margin-bottom: 10px;
    }
    label.active-choice > input:checked + div.checkmark:before {
      content: "\2714";
    }

    label.active-choice {
      background: $white;
      border-radius: 10px;
      border: 2px solid $primary;
      font-weight: 600;
    }
  }
  #arrow {
    display: none;
  }
  .answers {
    display: none;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    .option {
      width: 235px;
      height: 180px;
      border-radius: 10px;
      background: #f5f8ff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        margin: 20px 0;
      }
      div.svg {
        display: flex;
        flex-direction: row;
        img:first-of-type {
          margin-right: -5px;
        }
      }
      p.name {
        font-size: 14px;
        font-weight: 500;
      }
      p.description {
        font-size: 12px;
        font-weight: 400;
      }
      p.price {
        font-size: 12px;
        font-weight: 600;
      }
      p.more {
        position: absolute;
        bottom: -40px;
        color: #000;
        font-size: 10px;
        font-weight: 400;
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .active {
      background-color: $white;
      border-radius: 10px 10px 0px 0px;
      border: 2px solid $primary;
    }
    .active::after {
      content: "Sugerowana ochrona";
      width: 235px;
      height: 40px;
      color: $white;
      font-size: 14px;
      font-weight: 600;
      border-radius: 0px 0px 10px 10px;
      background: $primary;
      position: absolute;
      bottom: -40px;
      text-align: center;
      padding-top: 10px;
    }
  }
  .answerform {
    display: none;
    gap: 40px;
  }
  .sendform {
    display: none;
  }
  .flex {
    display: flex;
  }
}

@include media-breakpoint-down(sm) {
  .survey-panel {
    fieldset {
      flex-direction: column;
      gap: 20px;
      label {
        width: 100%;
      }
    }
    .answers {
      .option {
        width: 90%;
        height: 80px;
        .option-info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .description,
          .price {
            margin-bottom: 0;
          }
        }
      }
      .active {
        margin-bottom: 35px;
      }
      .active::after {
        width: 101%;
      }
    }
  }
}

#summary {
  border: none;
  width: 100%;
  outline: none;
  color: #2a2a28;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
