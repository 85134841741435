.offer.offer-page {
  h1.heading-offer {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  h2.heading-offer {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p.description {
    font-size: 16px;
    line-height: 1.5;
  }

  .card.card-grey {
    overflow: hidden;
    height: 560px;
    .card-body {
      .card-img {
        img {
          width: 100%;
          height: 300px;
          border-radius: 20px;
          object-fit: cover;
        }
      }
      .card-break {
        position: relative;
        width: 100%;
        height: 40px;
        background-color: #54566d;
        z-index: 10;
      }
    }
  }
  .container-gold {
    margin-top: 60px;
    padding: 50px 0;
    .client-support {
      padding-top: 10px;
    }
  }
}

.card-body {
  transition: all 0.5s;
  .arrow-offer-page {
    transition: all 0.5s;
  }
  .card-img {
    transition: all 0.5s;
    img {
      transition: all 0.5s;
    }
  }
}
.arrow-offer-page {
  opacity: 0;
}
.card-grey:hover {
  .card-img {
    background-color: $black;
    img {
      opacity: 0.6;
      transform: scale(1.2);
      overflow: hidden;
    }
  }
  .arrow-offer-page {
    opacity: 1;
  }
}
/* Styling for offer subpages */

.short-description {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.long-description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.heading-offer-subpage {
  font-size: 40px;
}

.how-it-works-img > img {
  width: 100%;
  max-width: 624px;
  height: auto;
  min-height: 425px;
  object-fit: cover;
  border-radius: 20px;
}

h2.how-it-works-heading,
h2.offer-services-desc-heading,
h2.faq-heading {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.how-it-works {
  position: relative;
  h3 {
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  img {
    position: absolute;
    left: 0;
    top: 1px;
    background-color: $secondary;
    padding: 5px;
    border-radius: 500px;
  }
}

.offer-services-desc {
  .service-info {
    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  .service-desc {
    gap: 20px;
    .service-desc-single {
      width: calc(32% - 7px);
      border-radius: 20px;
      background-color: #eef2ff;
      h3 {
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
}

.section-faq {
  .faq-question {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .long-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
}

@include media-breakpoint-down(md) {
  .offer-services-desc {
    .service-desc {
      .service-desc-single {
        width: 87%;
      }
    }
  }
}
