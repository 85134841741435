.header-cta {
  position: absolute;
  top: 30%;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 1;
  h1 {
    color: $white;
    font-weight: 500;
  }
  p {
    color: $white;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.48px;
    padding-top: 28px;
    padding-bottom: 56px;
  }
  .button-border {
    padding: 11px 0 11px 15px;
  }
}

.offer {
  p.heading-offer {
    font-size: 24px;
    font-weight: 500;
  }
  .card-grey {
    height: 420px;
    border-radius: 20px;
    position: relative;
    .arrow-bg {
      position: absolute;
      top: 12px;
      right: 12px;

      img {
        margin-right: 2px;
        margin-top: 1px;
      }
    }
    .card-content {
      position: absolute;
      left: 40px;
      bottom: 48px;
      h2 {
        color: $secondary;
        font-size: 32px;
        font-weight: 500;
      }
      p {
        color: $white;
        font-size: 18px;
      }
      .time {
        margin: 2px 0 0 10px;
      }
    }
  }
  .container-gold {
    margin-top: -284px;
    padding-bottom: 50px;
    .client-support {
      padding-top: 338px;
      h2 {
        color: $white;
        font-weight: 500;
      }
      .button-border {
        border: 2px solid $white;
      }
    }
    .client-services {
      // width: 87%;
      .card-client {
        // width: 30%;
        height: 279px;
        background: $client-gradient;
        overflow: hidden;
        border-radius: 20px;
        padding: 0;
        position: relative;
        img {
          width: 100%;
          height: 279px;
          object-fit: cover;
          opacity: 0.5;
        }
        .card-body {
          position: absolute;
          left: 29px;
          bottom: 29px;
          h3 {
            color: $secondary;
            font-size: 24px;
            font-weight: 700;
          }
          p {
            color: $white;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.opinions,
.realizations {
  .header {
    h2 {
      font-size: 40px;
      font-weight: 500;
    }
    p {
      font-size: 21px;
      font-weight: 400;
    }
  }
}
.opinions {
}
.realizations {
  .container-realizations {
    width: 1320px;
    margin: 0 auto;
    .single-realization {
      .single-gallery {
        text-align: center;
        .title {
          display: none;
        }
      }
      img {
        width: 90%;
        height: 265px;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }
}

@media all and (min-width: 768px) {
  .realizations {
    .container-realizations {
      .single-realization {
        a {
          display: block;
          position: relative;
          overflow: hidden;
          max-width: 450px;
          border-radius: 20px;
          z-index: 1;
        }
        a::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: $black;
          opacity: 0;
          transition: 0.5s ease all;
        }
        img {
          transition: 0.5s ease all;
          width: 100%;
          object-fit: cover;
        }
        a:hover img {
          transform: scale(1.2);
        }
        a:hover::after {
          opacity: 0.7;
        }
        a:hover .title {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: center;
          position: absolute;
          color: $white;
          z-index: 2;
          font-size: 1.5em;
          font-weight: 600;
          animation: moveOnHover 0.5s ease-in;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .realizations {
    .container-realizations {
      .single-realization {
        .single-gallery {
          display: flex;
          justify-content: center;
          a {
            width: 70%;
            max-width: 700px;
          }
        }
      }
    }
  }
}

@keyframes moveOnHover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}

@media all and (max-width: 991px) {
  .offer {
    .card-grey {
      height: 300px;
      margin-bottom: 50px;
    }
  }
}

@media all and (max-width: 575px) {
  .offer {
    .card-grey {
      .card-content {
        position: absolute;
        left: 20px;
        bottom: 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header-cta p,
  .offer p.heading-offer,
  .opinions .header p,
  .realizations .header p {
    font-size: 20px;
  }

  .opinions .header h2,
  .realizations .header h2,
  .cf-form-unikat h2 {
    font-size: 24px;
  }
}

@include media-breakpoint-down(md) {
  .header-cta p,
  .offer p.heading-offer,
  .opinions .header p,
  .realizations .header p {
    font-size: 18px;
  }

  .opinions .header h2,
  .realizations .header h2,
  .cf-form-unikat h2 {
    font-size: 22px;
  }
}
