.blog-page {
  .blog-single {
    .photo-hover {
      a {
        img {
          width: 100%;
          max-width: 600px;
          height: auto;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }
  }
}
.pagination {
  a {
    padding: 10px 20px;
    border: 2px solid $black;
    text-align: center;
    background-color: $white;
    border-radius: 20px;
  }
  a:hover {
    color: $black;
    background-color: $secondary;
    border: 2px solid $black;
  }
  .current {
    color: $white;
    padding: 10px 20px;
    border: 2px solid $black;
    text-align: center;
    background-color: $black;
    border-radius: 20px;
  }
}
