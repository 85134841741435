.realisations {
  a {
    display: flex;
    justify-content: center;
    .gallery-realizations {
      overflow: hidden;
      border-radius: 20px;
      img {
        width: 100%;
        height: auto;
        max-height: 300px;
        object-fit: cover;
        transition: all 0.3s;
      }
    }
  }
  a:hover {
    .gallery-realizations {
      img {
        transform: scale(1.2);
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  a {
    margin: 0 3px;
  }
}
