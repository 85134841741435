/* new styling */
.wpcf7 {
  .form-control {
    border-radius: 500px;
    color: #2a2a28;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    padding-left: 30px;
    border: 1px solid #2a2a28;
  }

  p > .wpcf7-form-control-wrap {
    .wpcf7-form-control {
      display: flex;
      justify-content: space-between;
      .wpcf7-list-item {
        color: #2a2a28;
        background-color: $white;
        font-size: 16px;
        font-weight: 600;
        width: 48%;
        max-width: 312px;
        height: 52px;
        border-radius: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          display: none;
        }
        .wpcf7-list-item-label {
          text-align: left;
          cursor: pointer;
        }
      }
      .wpcf7-list-item
        > label
        > input[type="radio"]:checked
        + .wpcf7-list-item-label {
        color: $white;
        background-color: $primary;
        font-size: 16px;
        font-weight: 600;
        width: 312px;
        height: 52px;
        border-radius: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .custom-control {
    p > .wpcf7-form-control-wrap {
      .wpcf7-acceptance {
        .wpcf7-list-item {
          font-size: 14px;
          font-weight: 400;
          background-color: transparent;
          width: 100%;
          max-width: 650px;
          label {
            display: flex;
            input {
              display: block;
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .wpcf7-list-item-label {
        padding-left: 10px;
      }
    }
  }
  .wpcf7-form-control.wpcf7-submit {
    margin-top: 50px;
    background-color: $primary;
    border-radius: 500px;
    height: 50px;
    color: $white;
    font-size: 18px;
    font-weight: 600;
  }
}

/* Old styles */

.cf7-step-confirm-title::first-letter {
  opacity: 0;
}

.cf7-step-confirm-name {
  text-align: left !important;
  width: 30% !important;
  padding-right: 15px !important;
  min-width: auto !important;
}

.wpcf7-list-item {
  margin: 0 !important;
}

.cf7-step-confirm-title {
  text-align: left !important;
}
.cf7-step-confirm-item {
  padding: 10px !important;
}

.cf7-step-confirm-value {
  text-align: left !important;
}

.wpcf7 {
  .alternative-radius {
    .form-control {
      border-radius: 24px;
    }
    textarea {
      height: 100px;
      padding-top: 20px;
    }
  }
}

@media all and (max-width: 768px) {
  .wpcf7 p > .wpcf7-form-control-wrap .wpcf7-form-control {
    flex-direction: column;
    gap: 30px;
    .wpcf7-list-item {
      width: 100%;
      max-width: 550px;
    }
  }
}
