.menu-mobile-box {
  position: fixed;
  top: 0;
  left: 416px;
  z-index: 999;
  background: $primary;
}

.menu-mobile-button {
  display: inline-block;
  cursor: pointer;
  border: none;
  padding: 0 28px;
  position: fixed;
  top: 17px;
  left: -15px;
  background-color: transparent;
}

.bar1,
.bar2,
.bar3 {
  width: 36px;
  height: 5px;
  background-color: $black;
  margin: 9px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: rotate(-45deg) translate(-15px, 13px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: rotate(45deg) translate(-6px, -6px);
}

.menu-mobile {
  width: 350px;
  display: none;
  background-color: $primary;
  position: absolute;
  left: -350px;
  top: 80px;
  animation-name: leftFadeIn;
  animation-duration: 2s;
}

.menu-mobile-item-box {
  margin: 25px;
}

.menu-mobile-item {
  display: block;
  padding: 20px 30px;
  transition: width 2s;
  color: $white;
}

.show {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// @media (min-width: 768px) {
//   .menu-mobile-box {
//     background: $primary;
//   }
// }

@media (max-width: 1159px) {
  .menu-mobile-box {
    left: 350px;
    top: -100px;
  }
  .menu-mobile {
    width: calc(100vw - 100px);
    height: 100vh;
  }
  .additional-info {
    margin: 25px;
  }
  .menu-mobile-item,
  .additional-info {
    padding: 20px 20px 20px 30px;
  }

  .menu-mobile-item-box {
    padding-top: 40px;
  }
  .menu-mobile-button {
    z-index: 999;
  }
  .menu-mobile-button:has(.change) {
    left: unset;
    right: 100px;
  }
  p.contact-bold {
    font-weight: 600;
  }
  .social-media a:nth-child(1),
  .social-media a:nth-child(2) {
    padding-right: 15px;
  }
}

@media (max-width: 576px) {
  .menu-mobile-item,
  .additional-info {
    padding: 15px 10px;
  }
  .menu-mobile-button:has(.change) {
    right: 170px;
  }
}

.mobile-white {
  background-color: $white;
}
