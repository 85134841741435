.featured-posts {
  .thumbnail {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      max-width: 400px;
      height: auto;
      border-radius: 20px;
      object-fit: cover;
    }
  }
}

.featured-posts-services {
  background-color: #eef2ff;
  margin-top: 60px;
  margin-bottom: -60px;
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }
  img {
    aspect-ratio: 3 / 2;
  }
  p.featured-post-header {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    .featured-read-more {
      font-size: 15px;
    }
  }
}
