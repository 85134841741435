* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: $body-color;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  overflow-x: hidden;
  background: $white;
}

.p-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.p-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.p-100 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.visibility {
  position: relative;
  z-index: 1;
}

.accent {
  color: $secondary;
}

.arrow-bg {
  display: inline;
  padding: 9px;
  background-color: $secondary;
  border-radius: 50px;
  margin-right: 3px;
}

.fit-content {
  width: fit-content;
}

.bold-heading {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.google-opinion {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 20px;
  margin: 0 auto;
}

.opinion-star {
  width: 20px;
}

.google-box-shortcode {
  margin: 0 auto;
}

@media all and (max-width: 576px) {
  .google-opinion {
    margin: 0 20px;
  }
}
