.about-us {
  .about-us-img {
    height: 50%;
    img {
      width: 100%;
      border-radius: 20px;
      object-fit: cover;
    }
  }
  .team-member-img img {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    border-radius: 20px;
  }
}

@media all and (max-width: 992px) {
  .about-us {
    .about-us-img {
      height: auto;
    }
    .team-member-img img {
      max-width: 600px;
      max-height: 600px;
    }
  }
}
