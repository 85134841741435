#home-slider {
  min-height: 85vh;
  background: $white;
  position: relative;
  overflow: hidden;
}

#home-slider .home-gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: $black;
}

.video-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  scale: 1;
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  padding-bottom: 56.25%;
  opacity: 0.4;
}

.video-wrapper iframe {
  height: calc(100% + 250px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  margin-top: -125px;
  opacity: 0.2;
}

@include media-breakpoint-down(lg) {
  #home-slider {
    min-height: 84vh;
  }
}

@media (max-width: 768px) {
  #home-slider {
    min-height: 80vh;
    position: relative;
  }
}

@media (max-width: 576px) {
  .video-wrapper {
    transform: translate(0%, 0%) !important;
    scale: 4 !important;
  }
}
