.header-logotype,
p.contact-white,
a.contact-white,
p.contact-black,
.header-logotype {
  margin: 20px 0;
}

p.contact-white,
a.contact-white,
p.contact-black {
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}

p.contact-white {
  color: $white;
  font-weight: 400;
}

p.contact-black {
  color: $black;
  font-weight: 400;
}

a.contact-white {
  color: $white;
  text-decoration: none;
  font-weight: 700;
}

a.contact-black {
  color: $black;
  text-decoration: none;
  font-weight: 700;
}

a.contact-white:hover {
  color: $white;
  text-decoration: underline;
}

a.contact-black:hover {
  color: $black;
  text-decoration: underline;
}
.arrow-black {
  width: 21px;
  margin-left: -8px;
  margin-top: -2px;
}

a.contact-mail {
  font-weight: 400;
  padding-right: 24px;
}

.sidebar {
  border-right: 1px solid #eaeaea;
  width: 65px;
  position: fixed;
  transform: translateZ(0);
  height: 100%;
  left: 0;
}

.frost-container {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px) saturate(160%) contrast(60%) brightness(125%);
}

.line {
  width: 100%;
  height: 1.2px;
  background-color: #d8dade;
}

@include media-breakpoint-down(md) {
  .container-menu-mobile {
    width: 100%;
    max-width: 768px;
    padding-left: 100px;
    padding-right: 30px;
  }
}
