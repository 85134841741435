.blog-post-single {
  .thumbnail {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 20px;
    }
  }
  .post-content {
    p {
      line-height: 1.4;
    }
  }
}
