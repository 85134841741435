/* General styles setting visibility of modal */
.modal-custom {
  width: 90vw;
  max-width: 1500px;
  height: 90vh;
  margin: 0 auto;
  z-index: 9999999;
}

@media all and (max-width: 767px) {
  .single-realization:nth-of-type(4),
  .single-realization:nth-of-type(5),
  .single-realization:nth-of-type(6) {
    display: none;
  }
  .modal-custom {
    max-width: 500px;
  }
}
@media all and (max-width: 575px) {
  .modal-custom {
    max-width: 290px;
  }
}

.modal-backdrop {
  opacity: 0.9 !important;
}

.modal {
  .modal-content {
    height: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .modal-header {
    border-bottom: none;
    margin-bottom: -70px;
    padding-right: 30px;
    position: relative;
    z-index: 9999999;
    .close {
      border: none;
      padding: 25px;
      background-color: transparent;
      img {
        width: 25px !important;
        height: 25px !important;
      }
    }
  }
  .modal-body {
    h3 {
      color: $white;
      font-weight: 600;
    }
    .carousel {
      .carousel-inner {
        .carousel-item {
          img {
            max-width: 800px;
            height: auto;
            max-height: 500px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
