.cf-form-unikat {
  h2 {
    font-size: 40px;
    font-weight: 500;
  }
  p {
    strong {
      font-weight: 500;
    }
  }
}
.contact {
}

@include media-breakpoint-down(md) {
  h2 .h2-contact-us {
    font-size: 24px;
  }
}

@include media-breakpoint-down(sm) {
  h2 .h2-contact-us {
    font-size: 22px;
  }
}
